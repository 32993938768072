import _ from 'lodash';
import { EditorReadyFn } from '@wix/yoshi-flow-editor';
import { NotificationType } from '@wix/platform-editor-sdk/lib';
import { EcomComponent, withEcomPlatformData } from '@wix/ecom-platform-sdk';
import { EcomComponentConfigurationStatic } from '@wix/ecom-platform-sdk/dist/src/types';

const GIFT_CARD_WIDGET_ID = '254a2b0f-97b3-4484-a29b-63562b1871e4';

const ecomComponentsConfiguration: EcomComponentConfigurationStatic = {
  ecomComponents: {
    [EcomComponent.CHECKOUT]: { isInstalled: true },
    [EcomComponent.THANK_YOU_PAGE]: { isInstalled: true },
  },
  shouldInstallMembersArea: false,
};

const giftCardEditorReady: EditorReadyFn = async (
  editorSDK,
  appDefinitionId,
  platformOptions,
  flowAPI,
) => {
  const t = flowAPI.translations.t;
  const allSitePages = await editorSDK.pages.data.getAll('');
  const giftCardPage = _.find(allSitePages, { tpaPageId: 'Gift Card' });

  const showAddToCart = async () => {
    const appData = await editorSDK.tpa.app.getDataByAppDefId(
      '',
      appDefinitionId,
    );
    const appComps = await editorSDK.tpa.app.getAllCompsByApplicationId(
      '',
      appData.applicationId,
    );

    const giftCardComponent = appComps.find(
      (x) => x.widgetId === GIFT_CARD_WIDGET_ID,
    );
    if (!giftCardComponent) {
      console.error(
        `Could not find gift card component of widget: ${GIFT_CARD_WIDGET_ID}`,
      );
      return;
    }

    const compRef = await editorSDK.document.components.getById('', {
      id: giftCardComponent.id,
    });

    await editorSDK.tpa.data.set('', {
      compRef,
      key: 'showAddToCartButton',
      value: true,
    });
  };

  if (platformOptions.firstInstall) {
    const type = 'success' as NotificationType;
    editorSDK.editor
      .showUserActionNotification('', {
        message: t(
          'settings.giftCardPage.floatingNotifcation.giftCardAdded.text',
        ),
        type,
        link: {
          caption: t(
            'settings.giftCardPage.floatingNotifcation.customizePage.link',
          ),
          onClick: () => {
            editorSDK.document.pages.navigateTo('', {
              pageRef: {
                id: giftCardPage?.id as string,
              } as any,
            });
          },
        },
      })
      .then((linkClicked) => {
        linkClicked &&
          editorSDK.document.pages.navigateTo('', {
            pageRef: {
              id: giftCardPage?.id as string,
            } as any,
          });
      });
  }
};

const exportsImpl = () => ({
  editor: {},
});

const platformApp = withEcomPlatformData(
  {
    editorReady: giftCardEditorReady as any,
    exports: exportsImpl,
  },
  ecomComponentsConfiguration,
);

export const { editorReady, exports } = platformApp;
