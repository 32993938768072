import React, { useEffect } from 'react';
import _ from 'lodash';
import { useTranslation, useExperiments } from '@wix/yoshi-flow-editor';
import s from './EnterCustomAmount.scss';
import { classes } from './EnterCustomAmount.st.css';
import { useControllerProps } from '../../ControllerContext';
import { useState } from '../../stores';
import settingsParams from '../../../settingsParams';

import { TextField } from 'wix-ui-tpa';
import validator from 'validator';
import { useSettings } from '@wix/tpa-settings/react';
import {
  currencyToMaxAllowedBalanceMap,
  DEFAULT_AMOUNT_MAX_LIMIT,
  SPECS,
} from '../../../../../consts';

const EnterCustomerAmount = () => {
  const settings = useSettings();

  const {
    state: { checkout },
    dispatch,
  } = useState();
  const {
    show_errors,
    purchase_options_validation,

    setPurchaseOptionsValidation,
  } = useControllerProps().giftCardCheckoutStore;
  const { currencyCode, locale, customVariant } =
    useControllerProps().giftCardStore;
  const { experiments } = useExperiments();

  useEffect(() => {
    checkCustomAmountValidation(
      checkout.purchaseOptions.customAmount.toString(),
    );
  }, [currencyCode]);

  const checkCustomAmountValidation = (value: string) => {
    setPurchaseOptionsValidation(
      'customAmount',
      validator.isFloat(value, {
        min: minCustomValue,
        max: maxCustomValue,
      }),
    );
  };
  const { t } = useTranslation();
  const [showCustomAmountError, setShowCustomAmountError] =
    React.useState(false);

  const getCurrencySymbol = () => {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })
      .formatToParts()
      .find((part) => part.type === 'currency')?.value;
  };

  const minCustomValue = customVariant?.min || 1;
  const maxCustomValue =
    experiments.enabled(SPECS.LimitMaxGiftCardCreateAmount) && currencyCode
      ? customVariant?.max ||
        (currencyToMaxAllowedBalanceMap[currencyCode] ??
          DEFAULT_AMOUNT_MAX_LIMIT)
      : customVariant?.max || 1000000000;

  const errorMessage = () => {
    if (Number(checkout.purchaseOptions.customAmount) < minCustomValue) {
      return t('giftCardPage.enterAmount.minimum.errorMessage').replace(
        '{minimumAmount}',
        minCustomValue.toString(),
      );
    } else if (Number(checkout.purchaseOptions.customAmount) > maxCustomValue) {
      return t('giftCardPage.enterAmount.maximum.errorMessage').replace(
        '{maximumAmount}',
        maxCustomValue.toString(),
      );
    } else {
      return '';
    }
  };

  return (
    <div className={s.textFieldContainer}>
      <TextField
        data-hook="rise-gift-card-enter-custom-amount-field"
        onBlur={() => {
          setShowCustomAmountError(true);
        }}
        maxLength={10}
        min={0}
        type="number"
        prefix={getCurrencySymbol()}
        className={classes.textField}
        value={checkout.purchaseOptions.customAmount.toString()}
        label={settings.get(settingsParams.enterCustomAmountLabel)}
        errorMessage={errorMessage()}
        error={
          (show_errors || showCustomAmountError) &&
          !_.get(purchase_options_validation, 'customAmount')
        }
        onChange={(e) => {
          const value = e.target.value;
          dispatch({
            type: 'setPurchaseOptions',
            payload: {
              customAmount: Number(value),
            },
          });
          checkCustomAmountValidation(value);
        }}
      />
    </div>
  );
};
export default EnterCustomerAmount;
