import React from 'react';
import { useSettings } from '@wix/tpa-settings/react';
import {
  useEnvironment,
  useExperiments,
  useModal,
  usePanorama,
} from '@wix/yoshi-flow-editor';
import { Button, ButtonPriority } from 'wix-ui-tpa';
import { classes } from './BuyNowButton.st.css';
import settingsParams from '../../../settingsParams';
import { useControllerProps } from '../../ControllerContext';
import { useState } from '../../stores';
import { PanoramaTransactions, SPECS } from '../../../../../consts';
import NoPremiumModal from '../../../modals/NoPremiumModal';

const BuyNowButton = () => {
  const {
    state: { checkout: checkoutState },
  } = useState();
  const settings = useSettings();
  const { isPreview } = useEnvironment();
  const { product, currencyCode } = useControllerProps().giftCardStore;
  const panorama = usePanorama();
  const { experiments } = useExperiments();
  const shouldShowNoPaymentMethodsModal = experiments.enabled(
    SPECS.AddNoPaymentMethodsModal,
  );

  const {
    createCheckout,
    reportCheckout,
    initShowNoPremiumModal,
    checkout,
    showNoPremiumModal,
    navigateToCheckout,
    creatingCheckout,
    addingToCart,
  } = useControllerProps().giftCardCheckoutStore;
  const { openModal: openPreviewBlockingModal } = useModal(
    'PreviewBlockingModal',
    {
      width: 520,
      height: 270,
      theme: 'BARE',
    },
  );

  if (checkout) {
    checkout.id && navigateToCheckout(checkout.id);
  }

  const handleBuyNowButtonClick = async () => {
    if (isPreview) {
      openPreviewBlockingModal();
    } else if (product) {
      panorama.transaction(PanoramaTransactions.giftCardBuyNow).start();
      createCheckout(
        product.product_id,
        checkoutState.purchaseOptions,
        currencyCode || '',
      );
      reportCheckout({
        product,
        purchase_options: checkoutState.purchaseOptions,
        currencyCode: currencyCode || '',
        type: 'buy now',
      });
      panorama.transaction(PanoramaTransactions.giftCardBuyNow).finish();
    }
  };

  return (
    <>
      <Button
        data-hook="rise-gift-card-buy-button"
        className={classes.button}
        fullWidth
        upgrade
        disabled={creatingCheckout || addingToCart}
        priority={ButtonPriority.primary}
        onClick={handleBuyNowButtonClick}
      >
        {settings.get(settingsParams.buyNowButtonText)}
      </Button>
      {shouldShowNoPaymentMethodsModal && (
        <NoPremiumModal
          isOpen={showNoPremiumModal}
          onModalClose={initShowNoPremiumModal}
        />
      )}
    </>
  );
};

export default BuyNowButton;
