import React from 'react';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import s from './ProductImage.scss';
import cx from 'classnames';
import { DefaultImageIcon } from './DefaultImageIcon';
import {
  Image,
  ImageLoadingBehaviorOptions,
  ImageHoverEffectOptions,
  ImageResizeOptions,
} from 'wix-ui-tpa';
import { useControllerProps } from '../../ControllerContext';
import { useState } from '../../stores';
import { SPECS } from '../../../../../consts';

export enum ProductImageDataHook {
  image = 'ProductImageDataHook.image',
  defaultImage = 'ProductImageDataHook.defaultImage',
}
const ProductImage = () => {
  const {
    state: { checkout },
  } = useState();
  const { isMobile } = useEnvironment();
  const { product, presetVariants } = useControllerProps().giftCardStore;
  const { experiments } = useExperiments();

  const shouldShowGiftCartImageLTR = experiments.enabled(
    SPECS.ShouldShowGiftCartImageLTR,
  );

  const selectedVariant = presetVariants.find(
    (variant) => variant.id === checkout.purchaseOptions.variantId,
  );

  const width = isMobile ? undefined : 452;
  const imageMedia = selectedVariant?.media || product?.media;
  const aspectRatio = imageMedia && imageMedia.width / imageMedia.height;

  const DefaultImage = () => {
    return (
      <div
        data-hook={ProductImageDataHook.defaultImage}
        className={cx(
          s.defaultImage,
          s.imageContainer,
          isMobile && s.defaultImageMobile,
          shouldShowGiftCartImageLTR && s.imageDirectionLTR,
        )}
      >
        <DefaultImageIcon />
      </div>
    );
  };

  return product && imageMedia?.url ? (
    <Image
      data-hook={ProductImageDataHook.image}
      className={
        shouldShowGiftCartImageLTR
          ? cx(s.imageContainer, s.imageDirectionLTR)
          : s.imageContainer
      }
      key={imageMedia.url}
      src={imageMedia.url}
      width={width}
      alt={imageMedia.alt_image}
      resize={ImageResizeOptions.contain}
      aspectRatio={aspectRatio}
      loadingBehavior={ImageLoadingBehaviorOptions.blur}
      hoverEffect={ImageHoverEffectOptions.zoom}
    />
  ) : (
    <DefaultImage />
  );
};

export default ProductImage;
