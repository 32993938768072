import React from 'react';
import _ from 'lodash';
import s from './ProductPrice.scss';
import { useControllerProps } from '../../ControllerContext';
import { useState } from '../../stores';

const ProductPrice = () => {
  const {
    state: { checkout },
  } = useState();
  const { currencyCode, locale, customVariant, presetVariants } =
    useControllerProps().giftCardStore;

  const formatCurrency = (value: number) => {
    try {
      const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });

      return formatter.format(value);
    } catch (error) {
      return value.toString();
    }
  };

  const productPrice = () => {
    if (checkout.purchaseOptions.variantId) {
      return priceVariant();
    } else {
      const minPriceVariant = _.minBy(presetVariants, 'price');
      const minPrice = minPriceVariant?.price;
      return formatCurrency(Number(minPrice) || 0);
    }
  };

  const priceVariant = () => {
    const selectedVariant = _.find(presetVariants, {
      id: checkout.purchaseOptions.variantId,
    });

    if (selectedVariant) {
      const variantPrice = formatCurrency(Number(selectedVariant?.price));
      if (selectedVariant?.price < selectedVariant?.value) {
        const variantValue = formatCurrency(Number(selectedVariant?.value));
        return (
          <>
            <span style={{ textDecoration: 'line-through' }}>
              {variantValue}
            </span>{' '}
            {variantPrice}
          </>
        );
      }

      return variantPrice;
    } else if (customVariant) {
      return formatCurrency(Number(checkout.purchaseOptions.customAmount));
    }
    return '';
  };

  return (
    <p data-hook="rise-gift-card-product-price" className={s.price}>
      {productPrice()}
    </p>
  );
};
export default ProductPrice;
